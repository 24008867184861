<template>
  <v-toolbar color="indigo lighten-5" flat>
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>

    <slot name="left">
      <BackButton />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </slot>

    <v-spacer></v-spacer>

    <v-text-field
      v-if="withSearch"
      v-model="search"
      class="shrink mr-10"
      append-icon="mdi-magnify"
      label="Пошук"
      single-line
      hide-details
      clearable
    />

    <slot name="right"></slot>
  </v-toolbar>
</template>

<script>
import BackButton from '@/components/common/BackButton'

export default {
  name: 'FormToolbar',

  components: { BackButton },

  emits: ['search'],

  props: {
    title: {
      type: String,
      required: true,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
    }
  },

  watch: {
    search(val) {
      this.$emit('search', val)
    },
  },
}
</script>
