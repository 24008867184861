<template>
  <div v-if="purchaseData && purchaseData.length" class="mt-3">
    <table v-if="totalPurchase && totalPurchase.payment_count && totalPurchase.payment_amount">
      <tr>
        <th colspan="2">Загалом покупки</th>
      </tr>
      <tr>
        <td>Кількість покупок</td>
        <td class="text-align-end font-weight-bold">
          {{ totalPurchase.payment_count.toLocaleString() }}
        </td>
      </tr>
      <tr>
        <td>Сума покупок, грн.</td>
        <td class="text-align-end font-weight-bold">
          {{ totalPurchase.payment_amount.toLocaleString() }}
        </td>
      </tr>
      <tr>
        <td>Активний клієнт</td>
        <td class="text-align-end font-weight-bold">
          {{ totalPurchase.is_active_client ? 'Так' : 'Ні' }}
        </td>
      </tr>
      <tr>
        <td>Дата останньої покупки</td>
        <td class="text-align-end font-weight-bold">
          {{ totalPurchase.last_payment_date | getShortDate }}
        </td>
      </tr>
    </table>
    <table v-if="companyPurchase && companyPurchase.length" class="mt-4">
      <tr>
        <th colspan="4">Покупки по компаніях</th>
      </tr>
      <tr>
        <th>Компанія</th>
        <th>Статус клієнта</th>
        <th>Кількість покупок</th>
        <th>Сума витрат, грн.</th>
      </tr>
      <tr v-for="item in companyPurchase" :key="item.company_id">
        <td>{{ item.company_name }}</td>
        <td>{{ item.is_active_client ? 'Активний' : 'Неактивний' }}</td>
        <td class="text-align-end font-weight-bold">
          {{ item.payment_count.toLocaleString() }}
        </td>
        <td class="text-align-end font-weight-bold">
          {{ item.payment_amount.toLocaleString() }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import convertDate from '@/mixins/convertDate'

export default {
  name: 'PurchaseTable',

  mixins: [convertDate],

  props: {
    purchaseData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    totalPurchase() {
      return this.purchaseData.filter((item) => !item.company_id)[0]
    },

    companyPurchase() {
      return this.purchaseData.filter((item) => !!item.company_id)
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;

  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 10px;
  }
}

.text-align-end {
  text-align: end;
}
</style>
